<template>
    <v-container fluid class="ma-0 pa-0" >
        <SimulationTabs/>
        <v-row style="min-height: calc(100% - 50px)">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mt-0 pt-0">
                <template>
                 
                    <router-view
                    ></router-view>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SimulationTabs from "./_components/simulacao_tabs.vue";
export default {
    components: {
        SimulationTabs,
    },
    data() {
        return {
            fornecedorProvider: { ano: parseInt(this.$moment().format("YYYY")), id_fornecedor: null },
        };
    },
    methods: {
        goTo(rota) {
            this.$router.push({ name: rota });
        }
  
    },
    provide() {
        // use function syntax so that we can access `this`
        return {
            fornecedorProvider: this.fornecedorProvider,
        };
    },
    computed: {
        usuarioTipo() {
            if (this.$store.getters.getUsuario.id_fornecedor) {
                return "fornecedor";
            }
            return "rd";
        },
    }
};
</script>

<style>

.min-width-100 {
	min-width: 100px;
}
.min-width-150 {
	min-width: 150px;
}
h2 {
	font-size: 1.3rem !important;
}
.highcharts-title {
	fill: #000 !important;
	font-weight: normal;
	font-size: 1.3rem !important;
	font-family: "TT Norms Pro" !important;
}
.verao-dark {
	background-color: #f2b36c;
}
.verao-light {
	background-color: #e8ad6a;
}
.inverno-light {
	background-color: #1190b9;
}
.inverno-dark {
	background-color: #0d8ab0;
}
.inverno {
	background-color: #0d9ac7;
}
.verao {
	background-color: #ffc07a;
}

.th-separador,
.th-separador {
	border-left: 0;
	border-right: 1px solid;
	border-image: linear-gradient(
		to bottom,
		transparent 20%,
		#1a796e 25%,
		#1a796e 75%,
		transparent 80%
	);
	border-image-slice: 1;
}

.cell-editable {
    background-color: #f2f2f2 !important;
    padding: 10px 10px;
    text-align: center;
    border-radius: 4px;
    margin: 0 5px;
}
.total-investido {
    background-color: #f2f2f2;
    border: 1px solid #eaeaea;
    display: inline-flex;
    border-radius: 8px;
    justify-content: end;
    align-content: center;
    font-weight: normal;
    overflow: hidden;
}
.total-investido .texto {
    padding: 7px 16px;
}
.total-investido .valor {
    background: #fff;
    padding: 7px 16px;
    color: #1e988a;
    font-weight: 500;
    flex-grow: 1;
    text-align: center;
}

hr.hr-cinza {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #eaeaea;
    margin: 1em 0;
    padding: 0;
}
.simulation-table .v-data-table__wrapper {
    font-weight: normal;
    border: 1px solid #ddd !important;
    border-radius: 10px 10px 0 0;
    padding: 0;
    /* overflow: hidden; */
}
.simulation-table td {
    color: #808080;
}
/* */

th {
    font-weight: normal;
    font-size: 0.9rem !important;
}
</style>
