<template>
	<v-row no-gutters>
		<v-col
			cols="12"
			align-self="end"
			sm="12"
			order="2"
			order-md="1"
			class="pl-3"
			md="12"
			lg="7"
			xl="7"
		>
			<v-tabs
				class="simulacao-tabs"
				active-class="activeMyClass"
				background-color="transparent"
				center-active
			>
				<v-tab @click="rotaMenuSimulacao('simulacaoResumo')"
					>Resumo</v-tab
				>
				<v-tab @click="rotaMenuSimulacao('simulacaoPdv')">PDV</v-tab>
				<!-- <v-tab @click="rotaMenuSimulacao('simulacaoTabloide')">Tablóide</v-tab> -->
				<v-tab @click="rotaMenuSimulacao('simulacaoCampanha')"
					>Campanha</v-tab
				>
				<v-tab @click="rotaMenuSimulacao('simulacaoEcommerce')"
					>Ecommerce</v-tab
				>
			</v-tabs>
		</v-col>
		<v-col
			cols="12"
			sm="12"
			md="12"
			order-md="2"
			order="1"
			lg="5"
			xl="5"
			class="px-1"
		>
			<v-row justify="center" justify-md="end">
				<div class="simulator-alert mt-0">
					<span style="font-weight:500"
						>SIMULADOR DE BUDGET - TRADE / ALERTA IMPORTANTE:</span
					><br />
					Esse simulador serve apenas de base para estimar
					investimento mensal.<br />
					Os valores são confirmados e validados nos lançamentos dos
					contratos.
				</div>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
export default {

	methods: {
		rotaMenuSimulacao(rota) {
			if (this.$route.name != rota) {
				this.$router.push({
					name: rota
				});
			}
		},
	},
	created() {
		if (this.$route.name != "simulacaoResumo") {
			this.rotaMenuSimulacao("simulacaoResumo");
		}
	},
};
</script>

<style>
.simulacao-tabs .activeMyClass:hover:before {
	background: none;
}
.simulacao-tabs .v-tab:before {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
}
.simulacao-tabs .v-tabs-slider {
	width: 70%;
	margin: 0 15%;
}
.simulacao-tabs .v-tab {
	width: 150px;
	font-size: 1.2rem;
	font-weight: normal !important;
	letter-spacing: 0px;
	color: #999 !important;
	text-transform: none !important;
}
.simulacao-tabs .activeMyClass {
	background: #fff;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	color: #000 !important;
}
</style>
